<template>
  <div>
    <h3 class="text-sm mb-4 font-semibold">Account Successfully Recovered!</h3>
    <ToznyForm
      :success="successMessage"
      :error="showError"
      :loading="loading"
      :onSubmit="onNewPassword"
    >
      <ToznyInput
        v-model="password"
        type="password"
        id="password"
        data-auto-id="new-password-input"
        name="password"
        class="mb-6"
        label="New Password"
        float-label
      />
      <ToznyInput
        v-model="confirmPassword"
        type="password"
        id="confirmPassword"
        data-auto-id="confirm-new-password-input"
        name="confirmPassword"
        class="mb-6"
        label="New Password Confirm"
        float-label
      />
      <div class="text-gray-dark text-tiny mb-12">
        Keep the same account recovery paper key!
      </div>
      <ToznyButton
        v-if="!successMessage"
        class="text-center w-full py-4 rounded-lg"
        buttonText="Change Password"
        :loading="loading"
        data-auto-id="change-password-button"
      />
      <div class="text-tozny flex" v-else>
        <ToznyLoader class="w-4 h-4 mt-1 mr-2" />
        <p>Sending you to login...</p>
      </div>
    </ToznyForm>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import ToznyForm from '@/Common/ToznyForm'
import ToznyButton from '@/Common/ToznyButton'
import ToznyInput from '@/Common/ToznyInput'
import ToznyLoader from '@/Common/ToznyLoader'
import { validatePassword } from '@/utils/utils'

export default {
  name: 'ResetPassword',
  components: {
    ToznyForm,
    ToznyButton,
    ToznyInput,
    ToznyLoader,
  },
  data: function () {
    return {
      password: '',
      confirmPassword: '',
    }
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
    passwordChanged: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    passwordsDoNotMatch: function () {
      return this.confirmPassword && this.confirmPassword !== this.password
    },
    showError: function () {
      if (this.passwordsDoNotMatch) {
        return 'Passwords do not match.'
      } else if (this.passwordNotValidError) {
        return this.passwordNotValidError
      } else {
        return this.error
      }
    },
    passwordNotValidError: function () {
      if (this.password) {
        return validatePassword(this.password)
      }
      return ''
    },
    successMessage: function () {
      if (this.passwordChanged) {
        return 'Password successfully changed!'
      }
      return ''
    },
  },

  methods: {
    ...mapActions(['pushRoute']),
    ...mapActions('account', ['clearChangePasswordErrorMessage']),
    routeToLogin() {
      this.pushRoute('/login')
    },
    async onNewPassword() {
      if (
        this.password &&
        !this.passwordsDoNotMatch &&
        !this.passwordNotValidError
      )
        this.$emit('newPassword', { newPassword: this.password })
    },
  },
  /*
    If the user has already received an API error,
    the watcher will clear the error state if the
    user enters new password information.  Without this,
    the user will get past the "Passwords do not match."
    error and see an outdated API error message.
  */
  watch: {
    passwordsDoNotMatch: function (val) {
      if (val === false) {
        this.clearChangePasswordErrorMessage()
      }
    },
  },
}
</script>
