<template>
  <DialogueBox
    data-auto-id="paper-key-dialogue"
    :showing="showing"
    title="Warning: Permanent Action"
  >
    <div class="p-4">
      <p>
        Only reset your password if you are sure you do not have access to your
        backup paper key. Resetting your password will also create a new paper
        key. <b>Do not lose this new paper key!</b>.
      </p>
      <p class="font-bold">
        <b>
          Resetting your password revokes access to backed up credentials and
          previous realms will lose a lot of functionality. This action cannot
          be undone.
        </b>
      </p>

      <div class="flex items-center">
        <button
          data-auto-id="continue-button"
          @click.prevent="$emit('continue')"
          class="m-0 mr-4"
        >
          Continue
        </button>
        <button
          data-auto-id="cancel-button"
          @click.prevent="$emit('cancel')"
          class="m-0 mr-4"
        >
          Cancel
        </button>
      </div>
    </div>
  </DialogueBox>
</template>

<script>
/* eslint-disable */
import DialogueBox from '@/Common/DialogueBox'
export default {
  name: 'InitiateEmailRecoveryDialogue',
  components: {
    DialogueBox,
  },
  props: {
    showing: Boolean,
  },
}
</script>
