<template>
  <div>
    <h3 class="text-sm mb-4 font-semibold">Recover Your Account</h3>
    <div v-if="recoveryMethodPaper === true">
      <p class="text-gray-dark text-tiny mb-4">
        Use the paper key given when account was created.
      </p>
      <ToznyForm
        :error="error"
        :loading="loading"
        :onSubmit="onLoginWithPaperKey"
      >
        <ToznyInput
          v-model="email"
          id="email"
          data-auto-id="email"
          type="email"
          name="email"
          class="mb-6"
          placeholder="name@example.com"
          label="Email"
          float-label
        />
        <ToznyInput
          v-model="paperKey"
          type="password"
          id="paperKey"
          data-auto-id="paper-key-input"
          name="paperKey"
          class="mb-6"
          label="Paper Key"
          float-label
        />

        <div class="text-gray-dark text-tiny text-right mb-12">
          <div class="flex justify-between mb-12 text-tiny">
            <a
              href="#!"
              class="no-underline text-tozny"
              @click.prevent="recoveryMethodPaper = false"
              >Forgot your paper key?</a
            >
            <div class="text-gray-dark">
              Remember your password?
              <a
                href="#!"
                class="no-underline text-tozny"
                @click.prevent="routeToLogin"
                >Log In</a
              >
            </div>
          </div>
        </div>
        <ToznyButton
          class="text-center w-full py-4 rounded-lg"
          buttonText="Recover"
          :loading="loading"
          data-auto-id="recover-button"
        />
      </ToznyForm>
    </div>

    <div v-if="recoveryMethodPaper === false">
      <p class="text-gray-dark text-tiny mb-4">
        We'll send a link to your email
      </p>
      <ToznyForm
        :error="error"
        :loading="loading"
        :success="success"
        :onSubmit="onInitiateRecoverWithEmail"
      >
        <ToznyInput
          v-model="email"
          id="email"
          data-auto-id="email"
          type="email"
          name="email"
          class="mb-6"
          placeholder="name@example.com"
          label="Email"
          float-label
        />

        <div class="text-gray-dark text-tiny text-right mb-12">
          <div class="flex justify-between mb-12 text-tiny">
            <a
              href="#!"
              class="no-underline text-tozny"
              @click.prevent="recoveryMethodPaper = true"
              >Remember your paper key?</a
            >
            <div class="text-gray-dark">
              Remember your password?
              <a
                href="#!"
                class="no-underline text-tozny"
                @click.prevent="routeToLogin"
                >Log In</a
              >
            </div>
          </div>
        </div>
        <ToznyButton
          class="text-center w-full py-4 rounded-lg"
          buttonText="Recover"
          :loading="loading"
          data-auto-id="recover-with-email-button"
        />
      </ToznyForm>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import ToznyForm from '@/Common/ToznyForm'
import ToznyButton from '@/Common/ToznyButton'
import ToznyInput from '@/Common/ToznyInput'
export default {
  name: 'RecoverPassword',
  components: {
    ToznyForm,
    ToznyButton,
    ToznyInput,
  },
  data: function () {
    return {
      recoveryMethodPaper: true,
      email: '',
      paperKey: '',
    }
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
    success: {
      type: String,
      default: '',
    },
  },
  methods: {
    ...mapActions(['login', 'pushRoute']),
    routeToLogin() {
      this.pushRoute('/login')
    },
    async onLoginWithPaperKey() {
      this.$emit('onLoginWithPaperKey', {
        paperKey: this.paperKey,
        email: this.email,
      })
    },
    async onInitiateRecoverWithEmail() {
      this.$emit('onInitiateRecoverWithEmail', this.email)
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/scss/authInputs.scss';
</style>
