<template>
  <div>
    <h3 class="text-sm mb-4 font-semibold">
      Warning: Account Recovery is Permanent
    </h3>
    <div v-if="displayError">
      <p class="text-error">
        {{ displayError }}
      </p>
    </div>
    <div v-if="loading">
      <p>Please wait while we authenticate your email ...</p>
      <div class="w-full text-center">
        <ToznyLoader class="w-8 h-8 text-tozny" />
      </div>
    </div>
    <div v-if="emailIsVerified">
      <p>
        If you're seeing this screen, it's because you requested to reset your
        password and no longer have access to your backup paper key.
      </p>
      <p>
        Only reset your password if you are sure you do not have access to your
        backup paper key.
      </p>
      <p class="font-bold">
        <b>
          Resetting your password revokes access to backed up credentials and
          previous realms will lose a lot of functionality. This action cannot
          be undone.
        </b>
      </p>
    </div>
    <div v-if="emailIsVerified">
      <ToznyForm
        :error="showError"
        :loading="loading"
        :onSubmit="completeAccountRecovery"
      >
        <div>
          <ToznyInput
            class="mb-6 flex-grow"
            v-model="newPassword"
            id="newPassword"
            label="New Password"
            type="password"
          />
        </div>
        <div>
          <ToznyInput
            class="mb-6 flex-grow"
            v-model="repeatNewPassword"
            id="repeatNewPassword"
            label="Repeat New Password"
            type="password"
          />
        </div>
        <div class="text-gray-dark text-tiny text-left mb-12">
          Remember your password?
          <a
            href="#!"
            class="no-underline text-tozny"
            @click.prevent="routeToLogin"
          >
            Log In
          </a>
        </div>
        <div>
          <ToznyButton
            v-if="emailIsVerified"
            class="w-full py-4 rounded-lg"
            buttonText="Reset My Account"
            :loading="false"
          />
        </div>
      </ToznyForm>
    </div>
    <PaperKeyDialogue
      :showing="showingPaperKey"
      @continue="onSeenPaperKey"
      :paperKey="paperKey"
      class="z-10"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import ToznyButton from '@/Common/ToznyButton'
import ToznyLoader from '@/Common/ToznyLoader'
import ToznyForm from '@/Common/ToznyForm'
import ToznyInput from '@/Common/ToznyInput'
import PaperKeyDialogue from '../../Register/PaperKeyDialogue'
import { validatePassword } from '@/utils/utils'

export default {
  name: 'verification',
  components: {
    ToznyButton,
    ToznyInput,
    ToznyLoader,
    ToznyForm,
    PaperKeyDialogue,
  },
  data: function () {
    return {
      loading: true,
      emailIsVerified: false,
      accountToken: '',
      displayConfirmationDialogue: false,
      newPassword: '',
      repeatNewPassword: '',
      showingPaperKey: false,
      paperKey: '',
    }
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    otp: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState(['displayError']),
    passwordsDoNotMatch: function () {
      // eslint-disable-next-line
      return (
        this.repeatNewPassword && this.repeatNewPassword !== this.newPassword
      )
    },
    showError: function () {
      if (this.passwordsDoNotMatch) {
        return 'Passwords do not match.'
      } else if (this.passwordNotValidError) {
        return this.passwordNotValidError
      } else {
        return this.displayError
      }
    },
    passwordNotValidError: function () {
      if (this.newPassword) {
        return validatePassword(this.newPassword)
      }
      return ''
    },
  },
  methods: {
    ...mapActions(['pushRoute', 'setValidationError', 'toggleLoading']),
    ...mapActions('account', ['authenticateEmail', 'recoverAccount']),
    routeToLogin() {
      this.pushRoute('/login')
    },
    routeToDashboard() {
      this.pushRoute('/')
    },
    async completeAccountRecovery() {
      if (this.passwordsDoNotMatch || this.passwordNotValidError) {
        return
      }
      this.loading = true
      const paperKey = await this.recoverAccount({
        newPassword: this.newPassword,
        accountToken: this.accountToken,
      })
      this.loading = false
      if (paperKey) {
        this.showingPaperKey = true
        this.paperKey = paperKey
      }
    },
    async onSeenPaperKey() {
      this.showingPaperKey = false
      this.pushRoute('/')
    },
  },
  mounted: async function () {
    if (this.id === '' || this.otp === '') {
      this.setValidationError(
        'We were unable to verify your email link, please try again.'
      )
      return
    }
    const emailVerified = await this.authenticateEmail({
      id: this.id,
      otp: this.otp,
    })
    if (emailVerified) {
      this.accountToken = emailVerified.token
      this.emailIsVerified = true
    }
    this.loading = false
  },
}
</script>
