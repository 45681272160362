<template>
  <AuthLayout>
    <template #info>
      <InfoPanel title="One Account" subtitle="Unlocks all our products" />
    </template>
    <template #form>
      <div v-if="completingEmailRecovery">
        <CompleteEmailRecovery
          :id="completingEmailRecovery.id"
          :otp="completingEmailRecovery.otp"
        />
      </div>
      <div v-else>
        <PaperKeyLogin
          v-if="!paperKeyValidated"
          @onLoginWithPaperKey="onLoginWithPaperKey"
          @onInitiateRecoverWithEmail="onInitiateRecoverWithEmail"
          :loading="loading"
          :error="showError"
          :success="success"
        />
        <NewPassword
          v-else-if="paperKeyValidated"
          :paperKey="paperKey"
          @newPassword="newPassword"
          :loading="changePasswordLoading"
          :error="changePasswordErrorMessage"
          :passwordChanged="passwordChangeSuccess"
          data-auto-id="change-password"
        />
      </div>
      <InitiateEmailRecoveryDialogue
        :showing="displayEmailRecoveryDialogue"
        @continue="continueRecoverWithEmail"
        @cancel="cancelRecoverWithEmail"
        class="z-10"
      />
    </template>
  </AuthLayout>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import NewPassword from './Components/NewPassword'
import PaperKeyLogin from './Components/PaperKeyLogin'
import CompleteEmailRecovery from './Components/CompleteEmailRecovery'
import InitiateEmailRecoveryDialogue from './Components/InitiateEmailRecoveryDialogue'

import AuthLayout from '../../Common/AuthLayout/AuthLayout'
import InfoPanel from '../../Common/AuthLayout/InfoPanel'
export default {
  name: 'RecoverPassword',
  components: {
    AuthLayout,
    InfoPanel,
    CompleteEmailRecovery,
    PaperKeyLogin,
    NewPassword,
    InitiateEmailRecoveryDialogue,
  },
  data: function () {
    return {
      email: '',
      paperKey: '',
      paperKeyValidated: false,
      passwordChangeSuccess: false,
      displayEmailRecoveryDialogue: false,
      emailValidationError: false,
      success: '',
    }
  },
  computed: {
    ...mapState(['displayError', 'loading']),
    ...mapState('account', [
      'changePasswordErrorMessage',
      'changePasswordLoading',
    ]),
    completingEmailRecovery() {
      const params = this.$route.query
      if (params.id && params.otp) {
        return params
      }
      return undefined
    },
    showError: function () {
      if (this.emailValidationError) {
        return 'Please provide a valid email'
      } else {
        return this.displayError
      }
    },
  },
  methods: {
    ...mapActions(['pushRoute']),
    ...mapActions('account', [
      'loginWithPaperKey',
      'initiateRecoverWithEmail',
      'changeAccountPassword',
      'recoverWithEmail',
    ]),
    routeToLogin() {
      this.pushRoute('/login')
    },
    passwordRecover() {
      this.pushRoute('/new-password')
    },
    async onLoginWithPaperKey(account) {
      account.email = account.email.toLowerCase()
      this.paperKey = account.paperKey
      const loggedIn = await this.loginWithPaperKey(account)
      this.paperKeyValidated = loggedIn
    },
    async onInitiateRecoverWithEmail(email) {
      if (this.validEmail(email) !== false) {
        this.email = email
        this.displayEmailRecoveryDialogue = true
        return
      }
    },
    validEmail(email) {
      if (email === '') {
        this.emailValidationError = true
        return false
      }
      this.emailValidationError = false
      return true
    },
    async continueRecoverWithEmail() {
      this.displayEmailRecoveryDialogue = false
      await this.initiateRecoverWithEmail(this.email.toLowerCase())
      this.success = 'Please check your email for an email reset link'
    },
    async cancelRecoverWithEmail() {
      this.displayEmailRecoveryDialogue = false
    },
    async newPassword({ newPassword }) {
      const changed = await this.changeAccountPassword({
        password: this.paperKey,
        newPassword: newPassword,
        type: 'paper',
      })
      this.passwordChangeSuccess = changed
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/scss/authInputs.scss';
</style>
